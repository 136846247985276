import React from 'react';
import {app} from '../config/firebase-config'
import {createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail} from "firebase/auth"

const signUp = ({email, password, role}) => {
    const auth = getAuth()
    return createUserWithEmailAndPassword(auth, email, password)
        .then( async (response) => {
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('accessToken', JSON.stringify(response.user.za));
        })
        .catch((error) => {
            return {error};
        });
};
const sendPwdResetEmail = ({email}) => {
    const auth = getAuth()
    return sendPasswordResetEmail(auth, email)
        .then( async () => {
            return {
                success: true,
            }
        })
        .catch((error) => {
            return {
                success: false,
                error
            };
        });
};
const logOut = () => {
    const auth = getAuth()
    return signOut(auth)
        .then( async (response) => {
            localStorage.removeItem('user');
            localStorage.removeItem('accessToken');
            window.location.href = '/auth'
        })
        .catch((error) => {
            return {success: false, error};
        });
};

const signIn = ({email, password}) => {
    const auth = getAuth()
    return signInWithEmailAndPassword(auth, email, password)
        .then((response) => {
            console.log(response);
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('accessToken', response?.user?.accessToken);

            return {
                success: true,
            }
            //window.location.href = '/backoffice'
        })
        .catch((error) => {
            return {
                success: false,
                error
            };
        });
};
export {
    signUp,
    signIn,
    logOut,
    sendPwdResetEmail,
}
