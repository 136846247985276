import {getAuth, onAuthStateChanged} from 'firebase/auth';
import React, {Component} from 'react'
import Http from "../utils/axios";
import LoaderComponent from "../components/LoaderComponent";

export default function RequireAuthenticationWrapper(AuthComponent) {
    const auth = getAuth()

    const Auth = {
        loggedIn: false,
    };

    return class Authenticated extends Component {
        state = {}

        constructor(props) {
            super(props)
            this.state = {
                isLoading: true
            };
        }

        componentDidMount() {
            if (this.state.isLoading) {
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        Http.setHeader('Authorization', 'Bearer ' + user?.accessToken)
                        localStorage.setItem('accessToken', user?.accessToken);
                        Auth.loggedIn = true;
                        this.setState({isLoading: false})
                    } else {
                        window.location.href = "/auth";
                    }
                });
            }
        }

        render() {
            return (
                <>
                    {this.state.isLoading ? (
                        <div id="page-transition" style={{textAlign: "center", marginTop: "15%"}}>
                            <LoaderComponent message={"Loading..."}/>
                        </div>
                    ) : (<AuthComponent {...this.props} auth={Auth}/>)
                    }
                </>
            )
        }
    }
}
