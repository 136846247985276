import React from "react";
import {useState, useEffect} from "react";

import CardTable from "../../../components/backoffice/Cards/CardTable";
import http from '../../../utils/axios'
import LoaderComponent from "../../../components/LoaderComponent";

function Order({hasNoFooter}) {
    const perpage = 20;
    const [customers, setCustomers] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [loading, setLoading] = useState(true)

    const exportStudentData = () => {
        http.setHeader('responseType', 'blob')
        http.get(`customers/downloads`)
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let rand = Math.floor(Math.random() * (10000 - 1) + 1)
                link.setAttribute('download', `clients${ +'-'+ new Date().getTime() + rand + '.csv'}`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
        });
    }
    const handleNextButton = () => {
        setLoading(true);
        http.get(`customers?topaginate=1&page=${metadata.page + 1}&perpage=${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.customers) {
                    setCustomers(data.data.data.customers);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        customers: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    const handlePreviousButton = () => {
        setLoading(true);
        http.get(`customers?topaginate=1&page=${metadata.page - 1}&perpage=${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.customers) {
                    setCustomers(data.data.data.customers);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        customers: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    const getCompletStatus = () => {
        setLoading(true);
        http.get(`customers${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}&status=0`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.customers) {
                    setCustomers(data.data.data.customers);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        customers: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    const getPenddingStatus = () => {
        setLoading(true);
        http.get(`customers${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}&status=1`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.customers) {
                    setCustomers(data.data.data.customers);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        customers: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    const getFailStatus = () => {
        setLoading(true);
        http.get(`customers${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}&status=2`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.customers) {
                    setCustomers(data.data.data.customers);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        customers: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    useEffect(() => {
        http.get(`customers${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.customers) {
                    setCustomers(data.data.data.customers);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        customers: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }, [])
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    {
                        !loading ? (
                            <CardTable
                                hasFilter={true}
                                exportStudentData={exportStudentData}
                                customers={customers}
                                metadata={metadata}
                                getCompletStatus={getCompletStatus}
                                getPenddingStatus={getPenddingStatus}
                                getFailStatus={getFailStatus}
                                handleNextButton={handleNextButton}
                                handlePreviousButton={handlePreviousButton} hasNoFooter={hasNoFooter}
                            />
                        ) : (<LoaderComponent/>)
                    }
                </div>
            </div>
        </>
    );
}


export default Order;
