import React from 'react'
import './admin-style.css'
import HeaderStats from "../components/backoffice/Headers/HeaderStats";
import Sidebar from "../components/backoffice/Sidebar/Sidebar";
import FooterAdmin from "../components/backoffice/Footers/FooterAdmin";
import AdminNavbar from "../components/backoffice/Navbars/AdminNavbar";
import RequireAuthenticationWrapper from "../wrappers/require-authentication-wrapper";

const AdminLayout = ({component: Component, ...props}) => {
    return (
        <>
            <Sidebar/>
            <div className="relative md:ml-64 bg-gray-50">
                <AdminNavbar/>
                {/* Header */}
                <HeaderStats/>
                <div className="px-4 md:px-10 mt20 mx-auto w-full -m-24">
                    <div className="bg-white gray-100 flex-1 p-6 md:mt-16">
                        <Component {...props} />
                    </div>
                    <FooterAdmin/>
                </div>
            </div>

        </>
    )
}

export default RequireAuthenticationWrapper(AdminLayout)
