import Login from "./pages/backoffice/auth";
import Dashboard from "./pages/backoffice/dashboard";
import Customer from "./pages/backoffice/customers/customers";
import CustomerDetails from "./pages/backoffice/customers/customer";
import CustomerEdit from "./pages/backoffice/customers/customerEdit";
import Reservations from "./pages/backoffice/reservastions/reservations";
import EmailResetPassword from "./pages/backoffice/email-reset-password";
import Order from "./pages/backoffice/customers/orders";

const routes = [
    {
        path: "/",
        component: Dashboard,
    },
    {
        path: "/auth",
        component: Login,
    },
    {
        path: "/auth/email-reset-password",
        component: EmailResetPassword,
    },
    {
        path: "/customers",
        component: Customer,
    },
    {
        path: "/customers/order-pending",
        component: Order,
    },
    {
        path: "/customers/:id",
        component: CustomerDetails,
    },
    {
        path: "/customers/:id/edit",
        component: CustomerEdit,
    },
    {
        path: "/reservations",
        component: Reservations,
    },
]

export default routes;
