import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import React, {useRef} from 'react'
import routes from 'router'
import Layout from 'layouts/index'
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import {getAuth, onAuthStateChanged } from 'firebase/auth'
import {app} from './config/firebase-config'

const Mainrouter = () => {
    const paths = window.location.href.split('/')
    const auth = getAuth();
    let currentUser = null;
    onAuthStateChanged(auth, (user) => {
        if (user) {
            currentUser = user;
        } else {
            currentUser = null
            if (!paths.includes('auth')) {
                window.location.href = '/auth'
            }
        }
    });
    return (
        <BrowserRouter>
            <Routes>
                {
                    routes.map((route, index) => (
                        (!paths.includes('auth')) ? (
                            <Route
                                path={route.path}
                                element={<AdminLayout component={route.component}/>}
                            />
                        ) : (
                            <Route
                                path={route.path} element={<AuthLayout component={route.component}/>}
                            />
                        )
                    ))
                }
            </Routes>
        </BrowserRouter>
    )
}

export default Mainrouter
