const typeVisas = [
    'Citizen',
    'Green card',
    'H1b',
    'O1',
    'Étudiant',
    'Autres'
]

export default typeVisas
/*
citizen ,green card , h1b , O1 , etudiant , )
 */
