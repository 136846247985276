import React, {useState} from "react";
import {useForm} from "react-hook-form";
import * as notifications from 'react-notifications-component';

import {signIn, sendPwdResetEmail} from "../../hooks/auth";
import FormValidations from "../../utils/custom-validation/form-validations";
import {Link} from "react-router-dom";

function EmailResetPassword() {
    const {register, formState: {errors}, handleSubmit} = useForm();
    const [isSent, setIsSent] = useState(false);
    const [processing, setProcessing] = useState(false);
    const formValidation = new FormValidations('fr');
    const onSignIn = async (data) => {
        setProcessing(true)
        notifications.store.removeNotification(1)
        notifications.store.removeNotification(2)
        notifications.store.removeNotification(3)
        notifications.store.addNotification({
            id: 1,
            title: "Information!",
            message: "Connexion en cours...",
            type: "info",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        const res = await sendPwdResetEmail(data).then((res) => {
            return res;
        });
        if (res.success) {
            setProcessing(false)
            notifications.store.removeNotification(1)
            notifications.store.removeNotification(2)
            notifications.store.removeNotification(3)
            notifications.store.addNotification({
                id: 2,
                title: "Information!",
                message: "Message envoyé avec succès.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            setIsSent(true)
            await new Promise(resolve => setTimeout(resolve, 6000));
        } else {
            setProcessing(false)
            notifications.store.removeNotification(1)
            notifications.store.removeNotification(2)
            notifications.store.removeNotification(3)
            notifications.store.addNotification({
                id: 3,
                title: "Erreur s'est produite!",
                message: "Cette adresse mail n'existe pas dans notre base de donnée.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    };
    return (
        <>
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    {
                        !isSent ?
                            <div className="w-full lg:w-4/12 px-4">
                                <div
                                    className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                                    <div className="rounded-t mb-0 px-6 py-6">
                                        <div className="text-center  py-10">
                                            <img src="/images/logo.png" style={{height: '45px'}} className='m-auto'
                                                 alt="Komi Afetse"/>
                                        </div>
                                        <div className="text-center mb-3">
                                            <h6 className="text-blueGray-500 text-sm">
                                                RÉCUPÉRATION DE MOT DE PASSE
                                            </h6>
                                        </div>

                                    </div>
                                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                        <form onSubmit={handleSubmit(onSignIn)}>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    E-Mail
                                                    <span className="text-red-500 ml-2">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    placeholder="E-Mail"
                                                    {...register('email', {
                                                        required: true,
                                                        pattern: {value: /\S+@\S+\.\S+/, message: ""}
                                                    })}
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                />
                                                {
                                                    (formValidation.has(errors.email?.type)) ? (
                                                        <p className="text-red-500 mt-3">
                                                            {formValidation.first(errors.email?.type, 'E-Mail', null, null)}
                                                        </p>
                                                    ) : null
                                                }
                                            </div>
                                            <div className="flex flex-wrap mt-6 relative">
                                                <div className="w-1/2">
                                                </div>
                                                <div className="w-1/2 text-right">
                                                    <Link to="/auth" className="text-blueGray-200">
                                                        <small> Se connecter </small>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="text-center mt-6">
                                                <button
                                                    disabled={processing}
                                                    className="bg-blueGray-800 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                >
                                                    {
                                                        processing ? (
                                                            <div className="flex">
                                                                <img src="/images/dural-ring.svg" style={{width: '28px'}}
                                                                     alt="" className='m-auto'/>
                                                            </div>
                                                        ) : (
                                                            <span>
                                                                ENVOYER
                                                            </span>
                                                        )
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div> : (
                                <div className="w-full lg:w-6/12 px-10 my-24">
                                    <div
                                        style={{backgroundColor: '#a5cda5', color: '#000'}}
                                        className="relative flex p-4 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                                        Un mail contenant un lien de réinitialisation de votre mot de passe a été envoyé,
                                        veuiller consulter votre boite mail pour procéder à la rénitialistion de votre mot
                                        de passe
                                    </div>
                                    <div className="flex flex-wrap mt-6 relative">
                                        <div className="w-1/2">
                                            <Link to="/auth" className="text-blueGray-200">
                                                <small> RETOUR </small>
                                            </Link>
                                        </div>
                                        <div className="w-1/2 text-right">
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </>
    );
}


export default EmailResetPassword;
