import React, {useState} from "react";
import {createPopper} from "@popperjs/core";
import {Link} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {store} from "react-notifications-component";
import http from "../../../utils/axios";

const ReservationTableDropdown = ({reservation, reloadData}) => {
    const [showModal, setShowModal] = useState(false);

    const statusSwitchCase = (caseValue) => {
        switch (caseValue) {
            case "COMPLÈTÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-green-600 rounded-full'> {caseValue} </span>;

            case "EN ATTENTE":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-yellow-600 rounded-full'> {caseValue} </span>;
            case "PAYÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-green-600 rounded-full'> {caseValue} </span>;

            case "NON PAYÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-blue-500 rounded-full'> {caseValue} </span>;

            case "ÉCHOUÉ":
            case "ECHOUÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-red-600 rounded-full'> {caseValue} </span>;

            default:
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-gray-600 rounded-full'> NON TRAITÉ </span>
        }
    }
    // dropdown props
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const [showSA, setShowSA] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "left-start",
        });
        setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const changeReservationStatus = () => {
        store.addNotification({
            id: 1,
            title: "Information!",
            message: "Changement en cours...",
            type: "info",
            insert: "bottom",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });

        http.get(`customers/change-reservations/status/${reservation.id}`)
            .then(async (resp) => {
                store.removeNotification(1)
                store.addNotification({
                    title: "Information!",
                    message: "Modification effectuer avec succès",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                reloadData()
            }).catch((error) => {
            store.removeNotification(1)
            store.addNotification({
                title: "Information!",
                message: "Une erreur est survenue lors des modification veuillez réessayer s'il vous plaît.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        })
    }
    const openSA = () => {
        setShowSA(true)
    }

    const onCancelSweetAlert = () => {
        console.log('changeReservationStatus');

    }

    return (
        <>
            <div
                onMouseLeave={() => closeDropdownPopover()}
            >
                <a
                    className="text-blueGray-500 py-1 px-3 cursor-pointer"
                    ref={btnDropdownRef}
                    onClick={(e) => {
                        e.preventDefault();
                        dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                    }}
                >
                    <i className="fas fa-ellipsis-v"> </i>
                </a>
                <div
                    ref={popoverDropdownRef}
                    className={
                        (dropdownPopoverShow ? "block " : "hidden ") +
                        "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                    }
                >
                    <a
                        href='javascript:void()'
                        onClick={() => openSA()}
                        className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                    >
                        Changer le Status
                    </a>
                    <a
                        onClick={() => setShowModal(true)}
                        href='javascript:void()'
                        className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                    >
                        Voir en détail
                    </a>
                </div>
            </div>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Détails
                                    </h3>

                                    <button
                                        className="p-1 ml-auto bg-white border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span
                                            className="-mt-3 -mr-3 text-3xl text-red-800 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                          ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div
                                    className="relative flex flex-col min-w-0 break-words w-full mb-2 rounded-lg bg-blueGray-100 border-0">
                                    <div className="flex-auto py-2 pt-0">
                                        <form>
                                            <div className="flex flex-wrap">
                                                <div className="w-full lg:w-6/12 px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Nom complet
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            value={reservation.username}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Addresse Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            value={reservation.email}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            N° de Téléphone
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            value={reservation.phoneNumber}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Status
                                                        </label>
                                                        <div
                                                            className="border-0 px3 py-3 text-left placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            {
                                                                statusSwitchCase(reservation.status)
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="flex flex-wrap">

                                                <div className="w-full lg:w-6/12 px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Type de VISA
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            value={reservation.typeVisa}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Status
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            value={reservation.country}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap">
                                                <div className="w-full px-4 my-3">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-left text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                            Raison
                                                        </label>
                                                        <textarea
                                                            rows={5}
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            value={reservation.reason}
                                                            readOnly={true}
                                                        > </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">

                                    <button
                                        className="text-white rounded bg-gray-700 hover:bg-gray-900 font-bold uppercase px-8 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <SweetAlert
                show={showSA}
                warning
                showCancel
                title="Changer le status"
                confirmBtnBsStyle="danger"
                onConfirm={changeReservationStatus}
                onCancel={() => {
                    setShowSA(false)
                }}
                customButtons={
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setShowSA(false)
                            }}
                            className="text-white mr-4 font-bold uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-red-500 active:bg-gray-900 hover:bg-red-700 ease-linear transition-all duration-150"
                        >
                            ANNULER
                        </button>

                        <button
                            onClick={changeReservationStatus}
                            className="text-white mr-4 font-bold uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-gray-700 active:bg-gray-900 hover:bg-gray-900 ease-linear transition-all duration-150"
                        >
                            CHANGER
                        </button>
                    </React.Fragment>
                }
                focusCancelBtn
            >
                <div>
                    <br/><br/>
                </div>
            </SweetAlert>
        </>
    );
};

export default ReservationTableDropdown;
