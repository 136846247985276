import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import './admin-style.css'
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import Navbar from "../components/backoffice/Navbars/AuthNavbar";

const AuthLayout = ({component: Component, ...props}) => {
    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            console.log('user', user?.accessToken);
            if (user) {
                window.location.href = "/";
            }
        });
    }, []);

    return (
        <>
            <Navbar transparent/>
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
                        style={{ backgroundColor: '#fff'}}
                    > </div>
                    <Component {...props} />
                    {/*
                    <FooterSmall absolute/>
                    */}
                </section>
            </main>
        </>
    )
}

export default AuthLayout
