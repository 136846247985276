import React, {useEffect, useState} from "react";
import http from '../../../utils/axios'
import LoaderComponent from "../../../components/LoaderComponent";
import ReservationTable from "../../../components/backoffice/Cards/ReservationTable";

function Reservations({hasNoFooter}) {
    const perpage = 20;
    const [reservations, setReservation] = useState([]);
    const [loading, setLoading] = useState(true)
    const [currentUrl, setCurrentUrl] = useState('customers/reservations')
    const [metadata, setMetadata] = useState([]);

    const handleNextButton = () => {
        setLoading(true);
        http.get(`${currentUrl}?topaginate=1&page=${metadata.page + 1}&perpage=${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.reservations) {
                    setReservation(data.data.data.reservations);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        reservations: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }
    const reloadData = () => {
        setLoading(true);
        http.get(`${currentUrl}?topaginate=1&page=${metadata.page}&perpage=${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.reservations) {
                    setReservation(data.data.data.reservations);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        reservations: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }
    const getCompletStatus = () => {
        setCurrentUrl('customers/reservations/completes')
        setLoading(true);
        http.get(`customers/reservations/completes${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                console.log('Reservation', data.data.data.reservations);
                if (data && data.data && data.data.data.reservations) {
                    setReservation(data.data.data.reservations);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        reservations: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    const loadReservationByStatus = (status=0) => {
        setLoading(true);
        http.get(`customers/reservations${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}&status=${status}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                console.log('Reservation', data.data.data.reservations);
                if (data && data.data && data.data.data.reservations) {
                    setReservation(data.data.data.reservations);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        reservations: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
        setCurrentUrl('customers/reservations')
    }
    const handlePreviousButton = () => {
        setLoading(true);
        http.get(`${currentUrl}?topaginate=1&page=${metadata.page - 1}&perpage=${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                if (data && data.data && data.data.data.reservations) {
                    setReservation(data.data.data.reservations);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        reservations: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }

    const exportReservationData = () => {
        http.setHeader('responseType', 'blob')
        http.get(`customers/reservations/downloads`)
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let rand = Math.floor(Math.random() * (10000 - 1) + 1)
                link.setAttribute('download', `reservations${ +'-'+ new Date().getTime() + rand + '.csv'}`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
        });
    }

    useEffect(() => {
        http.get(`customers/reservations${hasNoFooter ? '?topaginate=1&page=1&perpage=5' : '?topaginate=1&page=1&perpage='}${perpage}`)
            .then(async (response) => {
                let {data} = response
                data = {data};
                console.log('Reservation', data.data.data.reservations);
                if (data && data.data && data.data.data.reservations) {
                    setReservation(data.data.data.reservations);
                    setMetadata({
                        ...data.data.data,
                        perpage,
                        reservations: undefined,
                    })
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }, [])
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    {
                        !loading ? (
                            <ReservationTable
                                loadReservationByStatus={loadReservationByStatus}
                                exportReservationData={exportReservationData}
                                reservations={reservations}
                                metadata={metadata}
                                handleNextButton={handleNextButton}
                                handlePreviousButton={handlePreviousButton} hasNoFooter={hasNoFooter}
                                reloadData={reloadData}
                            />
                        ) : (<LoaderComponent/>)
                    }
                </div>
            </div>
        </>
    );
}

export default Reservations;
