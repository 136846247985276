import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";

import countries from "../../../utils/country-list";
import typeVisas from "../../../utils/visa-type";

import http from '../../../utils/axios'
import {useForm} from "react-hook-form";
import FormValidations from "../../../utils/custom-validation/form-validations";
import {store} from "react-notifications-component";
import LoaderComponent from "../../../components/LoaderComponent";

const yearCounts = []

for (let i = 0; i < 100; i++) {
    yearCounts.push(i);
}

function CustomerEdit() {
    const navigate = useNavigate();
    const {register, formState: {errors}, handleSubmit} = useForm();
    const formValidation = new FormValidations('fr');
    const {id} = useParams();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true)

    const onSubmit = async (data) => {
        data = {
            ...data,
            nbr_annee: parseInt(data.nbr_annee),
        }
        store.addNotification({
            id: 1,
            title: "Information!",
            message: "Modification en cours...",
            type: "info",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
        http.put(`customers/${customer.id}`, data)
            .then(async (resp) => {
                store.removeNotification(1)
                store.addNotification({
                    title: "Information!",
                    message: "Modification effectuer avec succès",
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                navigate(`/customers/${customer.id}`)
            }).catch((error) => {
            store.removeNotification(1)
            store.addNotification({
                title: "Information!",
                message: "Une erreur est survenue lors des modification veuillez réessayer s'il vous plaît.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        })
    };
    useEffect(() => {
        http.get(`customers/${id}`)
            .then(async (response) => {
                const {data} = response
                if (data && data.data) {
                    setCustomer(data.data);
                }
                setLoading(false)
            }).catch(error => {
            setLoading(false)
        });
    }, [])
    return (
        <>
            {
                !loading ? (
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">
                                    Modification des informations
                                </h6>

                                <Link
                                    to={'/customers'}
                                    className="text-white mr-4 font-bold uppercase text-sm pl-6 pr-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-gray-700 active:bg-gray-900 hover:bg-gray-900 ease-linear transition-all duration-150"
                                >
                                    Retour
                                    <i className="fas fa-sort-dow fa-sign-out-alt fa-2sx ml-6"> </i>
                                </Link>
                            </div>
                        </div>
                        {
                            (customer) ? (
                                <div className="flex-auto px- lg:px- 10 py-10 pt-0">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-6/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Nom complet
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={customer.username}
                                                        name='username'
                                                        {...register('username', {
                                                            required: true,
                                                            minLength: 8,
                                                            maxLength: 100
                                                        })}
                                                    />
                                                    {
                                                        (formValidation.has(errors.username?.type)) ? (
                                                            <p className="text-red-500 mt-3">
                                                                {formValidation.first(errors.username?.type, 'Nom complet', 8, 100)}
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Adresse Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={customer.email}
                                                        name='email'
                                                        {...register('email', {
                                                            required: true,
                                                            pattern: {value: /\S+@\S+\.\S+/, message: ""}
                                                        })}
                                                    />
                                                    {
                                                        (formValidation.has(errors.email?.type)) ? (
                                                            <p className="text-red-500 mt-3">
                                                                {formValidation.first(errors.email?.type, 'E-Mail', null, null)}
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        N° de Téléphone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={customer.phoneNumber}
                                                        name='phoneNumber'
                                                        {...register('phoneNumber', {
                                                            required: true,
                                                            pattern: {
                                                                value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                                                message: ""
                                                            }
                                                        })}
                                                    />
                                                    {
                                                        (formValidation.has(errors.phoneNumber?.type)) ? (
                                                            <p className="text-red-500 mt-3">
                                                                {formValidation.first(errors.phoneNumber?.type, 'N° de Téléphone', null, null, 'phone')}
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Profession
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={customer.profession}
                                                        name='profession'
                                                        {...register('profession', {
                                                            required: true,
                                                            minLength: 8,
                                                            maxLength: 100
                                                        })}
                                                    />
                                                    {
                                                        (formValidation.has(errors.profession?.type)) ? (
                                                            <p className="text-red-500 mt-3">
                                                                {formValidation.first(errors.profession?.type, 'Profession', 8, 100)}
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-12/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Niveau d'anglais
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={customer.english_level}
                                                        {...register('english_level', {
                                                            required: true,
                                                            minLength: 2,
                                                            maxLength: 100
                                                        })}
                                                    />
                                                    {
                                                        (formValidation.has(errors.english_level?.type)) ? (
                                                            <p className="text-red-500 mt-3">
                                                                {formValidation.first(errors.english_level?.type, "Niveau d'anglais", 2, 100)}
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Nombre d'année aux USA
                                                    </label>
                                                    <div className="relative">
                                                        <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                                             width="24"
                                                             height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                                                fill="#F8F8F8"/>
                                                        </svg>
                                                        <select
                                                            name="typeVisa"
                                                            {...register('nbr_annee', {
                                                                required: true
                                                            })}
                                                            defaultValue={customer.nbr_annee}
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <option value={null}> </option>
                                                            {
                                                                yearCounts.map(value => (
                                                                    <option key={value} value={value+''}>
                                                                        {value}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            (formValidation.has(errors.nbr_annee?.type)) ? (
                                                                <p className="text-red-500 mt-3">
                                                                    {formValidation.first(errors.nbr_annee?.type, `Nombre d'année aux USA`)}
                                                                </p>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Type de VISA
                                                    </label>

                                                    <div className="relative">
                                                        <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                                             width="24"
                                                             height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                                                fill="#F8F8F8"/>
                                                        </svg>
                                                        <select
                                                            name="typeVisa"
                                                            defaultValue={customer.typeVisa}
                                                            {...register('typeVisa', {
                                                                required: true
                                                            })}
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <option value={null}> </option>
                                                            {
                                                                typeVisas.map(value => (
                                                                    <option key={value} value={value} selected={true}>
                                                                        {value}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            (formValidation.has(errors.typeVisa?.type)) ? (
                                                                <p className="text-red-500 mt-3">
                                                                    {formValidation.first(errors.typeVisa?.type, 'Type de visa')}
                                                                </p>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-4/12 px-4 my-3">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Pays d'origine
                                                    </label>
                                                    <div className="relative">
                                                        <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                                             width="24"
                                                             height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                                                fill="#F8F8F8"/>
                                                        </svg>
                                                        <select
                                                            defaultValue={customer.country}
                                                            name="country"
                                                            {...register('country', {
                                                                required: true
                                                            })}
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <option value={null}> </option>
                                                            {
                                                                countries.map(value => (
                                                                    <option key={value} value={value}>
                                                                        {value}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            (formValidation.has(errors.country?.type)) ? (
                                                                <p className="text-red-500 mt-3">
                                                                    {formValidation.first(errors.country?.type, 'Pays')}
                                                                </p>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-12/12 px-4 my-3">
                                                <div className="relative w-1/5 mb-3">
                                                    <button
                                                        className="bg-blue-500 text-white active:bg-blue-400 my-4 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                    >
                                                        <i className="fa fa-save"> </i> ENREGISTRER
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            ): null
                        }
                    </div>
                ) : (<LoaderComponent/>)
            }

        </>
    );
}


export default CustomerEdit;
