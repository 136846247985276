import React from "react";
import PropTypes from "prop-types";
import 'moment/locale/fr'
import Moment from "react-moment";

// components
import ReservationTableDropdown from "../Dropdowns/ReservationTableDropdown";
import ReservationStatusFilterDropdown from "../Dropdowns/ReservationStatusFilterDropdown";

export default function ReservationTable(
    {
        exportReservationData,
        reservations,
        metadata,
        handleNextButton,
        handlePreviousButton,
        hasNoFooter,
        getCompletStatus,
        loadReservationByStatus,
        getPenddingStatus,
        reloadData,
    }) {
    const statusSwitchCase = (caseValue) => {
        switch (caseValue) {
            case "COMPLÈTÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-green-600 rounded-full'> {caseValue} </span>;

            case "EN ATTENTE":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-yellow-600 rounded-full'> {caseValue} </span>;
            case "PAYÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-green-600 rounded-full'> {caseValue} </span>;

            case "NON PAYÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-blue-500 rounded-full'> {caseValue} </span>;

            case "ÉCHOUÉ":
            case "ECHOUÉ":
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-red-600 rounded-full'> {caseValue} </span>;

            default:
                return <span
                    className='inline-flex w-3/4 items-center justify-center px-4 py-1 mr-2 text-xs font-semibold leading-none text-white bg-gray-600 rounded-full'> NON TRAITÉ </span>
        }
    }
    return (
        <>
            <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 rounded "}>
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex justify-between">
                            <div className="w-1/4">
                                <h3
                                    className={"font-semibold text-lg"}
                                >
                                    Reservations
                                </h3>
                            </div>

                            <div className='3/4 flex'>
                                <button
                                    className="text-white mr-4 font-semibold uppercase text-sm pl-6 pr-6 pt-2 pb-2 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-gray-700 active:bg-gray-900 hover:bg-gray-900 ease-linear transition-all duration-150"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        exportReservationData()
                                    }}
                                >
                                    Exporter tout
                                    <i className="fas fa-sort-dow fa-file-download fa-2sx ml-2"> </i>
                                </button>
                                <ReservationStatusFilterDropdown
                                    loadReservationByStatus={loadReservationByStatus}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                        <tr>
                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Nom complet
                            </th>
                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Email
                            </th>
                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                N° de Tel
                            </th>

                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Type de VISA
                            </th>

                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Pays
                            </th>

                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Status
                            </th>

                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                DATE
                            </th>

                            <th
                                className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "

                            >Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reservations.map(reservation => {
                                return (
                                    <tr>
                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                            {reservation.username}
                                        </th>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {reservation.email}
                                        </td>

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {reservation.phoneNumber}
                                        </td>


                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {reservation.typeVisa}
                                        </td>

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {reservation.country}
                                        </td>

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {
                                                statusSwitchCase(reservation.status)
                                            }
                                        </td>

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <Moment
                                                unix format="ddd Do MMM YYYY"
                                                locale={'fr'}
                                            >{reservation?.createdAt}</Moment>
                                        </td>

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                            <ReservationTableDropdown reservation={reservation}
                                                                      reloadData={reloadData}/>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        {
                            (reservations.length === 0) ? (
                                <tr>
                                    <th colSpan={7} className={'py-5'}>
                                        Aucune donnée trouvée
                                    </th>
                                </tr>
                            ) : null
                        }
                        </tbody>
                    </table>

                    {
                        !hasNoFooter ? (
                            <div
                                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                            >
                                <div className="flex-1 flex justify-between sm:hidden">
                                    <button
                                        onClick={() => {
                                            handlePreviousButton()
                                        }}
                                        disabled={(metadata.currentPage === 1)}
                                        className={`relative inline-flex items-center px-6 py-3 mr- rounded-md border border-gray-300 bg-white text-sm font-medium ${!(metadata.currentPage === 1) ? 'bg-gray-800 hover:bg-gray-900 text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
                                    >
                                        <i className="fas fa-step-backward mr-2"> </i> <span
                                        className="uppercase"> Préc </span>
                                    </button>

                                    <button
                                        disabled={metadata.totalPages === metadata.currentPage}
                                        onClick={() => {
                                            handleNextButton()
                                        }}
                                        className={`relative inline-flex items-center px-6 py-3 ml-4 rounded-md border border-gray-300 bg-white text-sm font-medium ${!(metadata.totalPages === metadata.currentPage) ? 'bg-gray-800 hover:bg-gray-900 text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
                                    >
                                        <span className="uppercase">Suiv</span> <i
                                        className="fas fa-step-forward ml-2"> </i>
                                    </button>
                                </div>
                                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <p className="text-sm text-gray-700">
                                            <span
                                                className="font-medium"> {(metadata.page * metadata.perpage) - metadata.perpage + 1}</span> à <span
                                            className="font-medium">
                                    {((metadata.perpage * metadata.page) < metadata.total) ? metadata.perpage * metadata.page : metadata.total}
                                </span> sur {' '}
                                            <span className="font-medium">
                                        {metadata.total}
                                    </span> Éléments
                                        </p>
                                    </div>
                                    <div>
                                        <nav className="relative z-0 inline-flex rounded-md shadow-sm"
                                             aria-label="Pagination">
                                            <button
                                                onClick={() => {
                                                    handlePreviousButton()
                                                }}
                                                disabled={(metadata.currentPage === 1)}
                                                className={`relative inline-flex items-center px-6 py-3 mr- rounded-md border border-gray-300 bg-white text-sm font-medium ${!(metadata.currentPage === 1) ? 'bg-gray-800 hover:bg-gray-900 text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
                                            >
                                                <i className="fas fa-step-backward mr-2"> </i> <span
                                                className="uppercase"> Préc </span>
                                            </button>

                                            <button
                                                disabled={metadata.totalPages === metadata.currentPage}
                                                onClick={() => {
                                                    handleNextButton()
                                                }}
                                                className={`relative inline-flex items-center px-6 py-3 ml-4 rounded-md border border-gray-300 bg-white text-sm font-medium ${!(metadata.totalPages === metadata.currentPage) ? 'bg-gray-800 hover:bg-gray-900 text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
                                            >
                                                <span className="uppercase">Suiv</span> <i
                                                className="fas fa-step-forward ml-2"> </i>
                                            </button>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </>
    );
}

ReservationTable.defaultProps = {
    color: "light",
};

ReservationTable.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};
