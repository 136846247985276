import React from 'react'
import {Ring} from 'react-awesome-spinners'

const LoaderComponent = ({ message }) => {
    return (
        <>
            <div className="text-center">
                <Ring size={128}/>
                <div className='mt-20'>
                    { message?? "Chargement des données..."}
                </div>
            </div>
        </>
    )
}

export default LoaderComponent
