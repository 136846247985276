import React, { useState } from 'react'
import logo from 'assets/images/logo.png'
import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

const Navbar = () => {
    const [open, setOpen] = useState(false)
    const handleClick = () => setOpen(!open)
    return (

        <header className="container xl:max-w-7xl mx-auto lg:px-16 px-4">
            <div className="flex flex-col md:items-center md:justify-between md:flex-row py-4 md:py-6">
                <div className="flex flex-row items-center justify-between">
                    <Link to="/" className="text-2xl font-black leading-[29px] text-black uppercase"><img
                        src={logo} alt="" className="h-8" /></Link>
                    <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline" onClick={handleClick} >
                        <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                            {open ? (<path x-show="open" fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"></path>) : (<path x-show="!open" fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                    clipRule="evenodd"></path>)}


                        </svg>
                    </button>
                </div>
                <nav
                    className={`  flex-col flex-grow  pb-4 md:pb-0 md:flex md:justify-end md:flex-row md:space-x-9 ${open ? 'flex' : 'hidden'}`}>
                    <Link className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4" to="/">Coaching</Link>
                    <Link className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4" to="/my-history">Mon
                        histoire</Link>

                    <a className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4"
                        href="https://amzn.to/3DCTHcH" target="_blank" rel='noreferrer'>Mes
                        livres</a>
                    <NavHashLink
                        to="/#price"
                        activeClassName="selected"
                        className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4"
                        activeStyle={{ color: 'red' }}
                    > Mes Prix </NavHashLink>
                </nav>
            </div >
        </header >
    )
}

export default Navbar
