import React from "react";
import {createPopper} from "@popperjs/core";
import {Link} from "react-router-dom";

const CustomerStatusFilterDropdown = ({getCompletStatus, getPenddingStatus, getFailStatus}) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start",
        });
        setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };
    return (
        <div onMouseLeave={() => closeDropdownPopover()}>
            <button
                className="text-white font-semibold uppercase text-sm pl-6 pr-6 pt-2 pb-3 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-gray-700 active:bg-gray-900 hover:bg-gray-900 ease-linear transition-all duration-150"
                ref={btnDropdownRef}
                onClick={(e) => {
                    e.preventDefault();
                    dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                }}
            >
                Filtrer
                <i className="fas fa-sort-down fa-2x ml-6"> </i>
            </button>

            <div
                ref={popoverDropdownRef}
                className={
                    (dropdownPopoverShow ? "block " : "hidden ") +
                    "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-56"
                }
            >
                <a
                    href='javascript:void()'
                    onClick={(e) => {
                        e.preventDefault();
                        getPenddingStatus()
                    }}
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                >
                    EN ATTENTE
                </a>
                <a
                    href='javascript:void()'
                    onClick={(e) => {
                        e.preventDefault();
                        getCompletStatus()
                    }}
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                >
                    COMPLÈTÉ
                </a>
                <a
                    href='javascript:void()'
                    onClick={(e) => {
                        e.preventDefault();
                        getFailStatus()
                    }}
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                >
                    ÉCHOUÉ
                </a>
            </div>
        </div>
    );
};

export default CustomerStatusFilterDropdown;
