import React from "react";

import UserDropdown from "components/backoffice/Dropdowns/UserDropdown.js";
import {Link} from "react-router-dom";

export default function AdminNavbar() {
    return (
        <>
            {/* Navbar */}
            <nav
                className="absolute top-0 left-0 w-full z-10 bg-black md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
                <div
                    className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                    {/* Brand */}
                    <Link
                        to={'/'}
                        className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
                    >
                        Tableau de bord
                    </Link>

                    {/* User */}
                    <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
                        <UserDropdown/>
                    </ul>
                </div>
            </nav>
            {/* End Navbar */}
        </>
    );
}
