import axios from 'axios';
import {store} from "react-notifications-component";

axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT;
// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// Response
axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async ({response}) => {
        if (response.status === 403) {
            store.removeNotification(1);
            store.addNotification({
                id: 1,
                title: "Information!",
                message: "Votre session est expirée, veuillez reconnecter s'il vous plaît.",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 4998,
                    onScreen: true
                }
            });
            await new Promise(resolve => setTimeout(resolve, 5000));
            window.location.href = '/auth'
            console.log('Tu peux me rediriger maintenant');
        }
        return Promise.reject(response);
    }
);
class Http extends axios {
    constructor() {
        super()
    }
    static setHeader = function (key, value) {
        this.defaults.headers.post[key] = value;
    }
}

export default Http;
