import messages from './lang'

class FormValidations {
    messages = {};
    constructor(lang = null) {
        this.messages = messages[lang || 'fr'];
    }

    has(field) {
        return !!field;
    }

    first(key, field, value0 = null, value1 = null, paternKey= 'email') {
        if (key) {
            console.log(key);
            if (key === "maxLength") {
                value0 = value1;
            }
            if (key === 'pattern') {
                return (this.messages[paternKey]) ? this.messages[paternKey].replace(':field', field.toLowerCase()) : null;
            }
            return (this.messages[key]) ? this.messages[key].replace(':value', value0).replace(':value1', value1)
                .replace(':field', field.toLowerCase()) : null;
        }
    }
}
export default FormValidations
