import React from "react";
import { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";

import CardTable from "../../../components/backoffice/Cards/CardTable";
import http from '../../../utils/axios'
import LoaderComponent from "../../../components/LoaderComponent";

function CustomerDetails() {
    const {id} = useParams();
    const [loading, setLoading] = useState(true)
    const [customer, setCustomer] = useState({});
    useEffect(() => {
        http.get(`customers/${id}`)
            .then(async (response) => {
                const {data} = response
                if (data && data.data) {
                    setCustomer(data.data);
                }
                setLoading(false)
            }).catch(error => {
            setLoading(false)
        });
    }, [])
    return (
        <>
            {
                !loading ? (
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">
                                    Information sur le client
                                </h6>
                                <div className="">
                                    <Link
                                        to={'/customers'}
                                        className="text-white mr-4 font-bold uppercase text-sm pl-6 pr-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-gray-700 active:bg-gray-900 hover:bg-gray-900 ease-linear transition-all duration-150"
                                    >
                                        Retour
                                        <i className="fas fa-sort-dow fa-sign-out-alt fa-2sx ml-6"> </i>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className="flex-auto py-10 pt-0">
                            <form>
                                <div className="flex flex-wrap">
                                    <div className="w-full lg:w-6/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Nom complet
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.username}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Addresse Email
                                            </label>
                                            <input
                                                type="email"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.email}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                N° de Téléphone
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.phoneNumber}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Profession
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.profession}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-wrap">
                                    <div className="w-full lg:w-6/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Status
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.status}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Niveau d'anglais
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.english_level}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Nombre d'année aux USA
                                            </label>
                                            <input
                                                type="email"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.nbr_annee}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Type de VISA
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.typeVisa}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 my-3">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Pays
                                            </label>
                                            <input
                                                type="text"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={customer.country}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="ml-3 mt-4 text-left">
                                <Link
                                    to={`/customers/${customer.id}/edit`}
                                    className="text-white mr-4 font-bold uppercase text-sm pl-6 pr-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none bg-gray-700 active:bg-gray-900 hover:bg-gray-900 ease-linear transition-all duration-150"
                                >
                                    Modifier
                                    <i className="fas fa-sort-dow fa-edit sign-out-alt fa-2sx ml-6"> </i>
                                </Link>
                            </div>
                        </div>
                    </div>
                ) : (
                    <LoaderComponent/>
                )
            }
        </>
    );
}


export default CustomerDetails;
