import React, {useEffect, useState} from "react";
import CardStats from "../../components/backoffice/Cards/CardStats";
import http from "../../utils/axios";
import Customer from "./customers/customers";

function Dashboard() {
    const [stats, setStats] = useState({
        admins: 0,
        reservations: 0,
        customers: 0,
        wallets: 0,
        free: 0,
        gold: 0,
        silver: 0,
        diamond: 0,
    });
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        http.get(`customers/stats`)
            .then(async (response) => {
                const {data} = response
                if (data && data.data) {
                    setStats(data.data);
                    setLoading(false);
                }
            }).catch(error => {
            setLoading(false);
        });
    }, [])

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <CardStats
                        statSubtitle="PACK GRATUIT"
                        statTitle={stats.free}
                        statArrow="up"
                        statPercent="12"
                        statPercentColor="text-emerald-500"
                        statDescripiron="Since last month"
                        statIconName="fas fa-percent"
                        statIconColor="bg-lightBlue-500"
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <CardStats
                        statSubtitle="PACK ARGENT"
                        statTitle={stats.silver}
                        statPercentColor="text-emerald-500"
                        statDescripiron="Since last month"
                        statIconName="far fa-chart-bar"
                        statIconColor="bg-yellow-400"
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <CardStats
                        statSubtitle="PACK OR"
                        statTitle={stats.gold}
                        statArrow="down"
                        statPercent="3.48"
                        statPercentColor="text-red-500"
                        statDescripiron="Since last week"
                        statIconName="fas fa-chart-pie"
                        statIconColor="bg-yellow-600"
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <CardStats
                        statSubtitle="PACK DIAMANT"
                        statTitle={stats.diamond}
                        statArrow="down"
                        statPercent="1.10"
                        statPercentColor="text-orange-500"
                        statDescripiron="Since yesterday"
                        statIconName="fas fa-users"
                        statIconColor="bg-pink-500"
                    />
                </div>
            </div>

            <div className="flex flex-wrap mt-5">
                {/*
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                    <CardStats
                        statSubtitle="PORTEFEUILLE"
                        statTitle={ `${ new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'usd' }).format(stats.wallets ?? 0)}`}
                        statArrow="up"
                        statPercent="12"
                        statPercentColor="text-emerald-500"
                        statDescripiron="Since last month"
                        statIconName="fas fa-percent"
                        statIconColor="bg-lightBlue-500"
                    />
                </div>
                */}
                <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <CardStats
                        statSubtitle="ÉTUDIANTS"
                        statTitle={stats.customers}
                        statPercentColor="text-emerald-500"
                        statDescripiron="Since last month"
                        statIconName="far fa-users"
                        statIconColor="bg-red-500"
                        link={'customers'}
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <CardStats
                        statSubtitle="RESERVATIONS"
                        statTitle={stats.reservations}
                        statArrow="down"
                        link={'reservations'}
                        statPercent="3.48"
                        statPercentColor="text-red-500"
                        statDescripiron="Since last week"
                        statIconName="fas fa-phone"
                        statIconColor="bg-orange-500"
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-4/12 px-4">
                    <CardStats
                        statSubtitle="ADMINISTRATEURS"
                        statTitle={stats.admins}
                        statPercent="1.10"
                        statPercentColor="text-orange-500"
                        statDescripiron="Since yesterday"
                        statIconName="fas fa-users"
                        statIconColor="bg-pink-500"
                    />
                </div>
            </div>

            <div className="flex flex-wrap mb-10">
                <br/>
            </div>
            <Customer hasNoFooter={true}/>
        </>
    );
}


export default Dashboard;
