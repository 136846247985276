const messages = {
    "fr": {
        "alpha": "Le champ :field ne peut contenir que des lettres",
        "alpha_num": "Le champ :field ne peut contenir que des caractères alpha-numériques",
        "alpha_dash": "Le champ :field ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
        "alpha_spaces": "Le champ :field ne peut contenir que des lettres ou des espaces",
        "range": "Le champ :field doit être compris entre :value et :value1",
        "confirmed": "Le champ :field ne correspond pas",
        "digits": "Le champ :field doit être un nombre entier de :value chiffres",
        "dimensions": "Le champ :field doit avoir une taille de {width} pixels par {height} pixels",
        "email": "Le champ :field doit être une adresse e-mail valide",
        "phone": "Le champ :field doit être un numéro de téléphone correcte",
        "excluded": "Le champ :field doit être une valeur valide",
        "ext": "Le champ :field doit être un fichier valide",
        "image": "Le champ :field doit être une image",
        "integer": "Le champ :field doit être un entier",
        "length": "Le champ :field doit contenir :value caractères",
        "max": "Le champ :field doit avoir une valeur de :value ou moins",
        "maxLength": "Le champ :field ne peut pas contenir plus de :value caractères",
        "mimes": "Le champ :field doit avoir un type MIME valide",
        "min": "Le champ :field doit avoir une valeur de :value ou plus",
        "minLength": "Le champ :field doit contenir au minimum :value caractères",
        "number": "Le champ :field ne peut contenir que des chiffres",
        "oneOf": "Le champ :field doit être une valeur valide",
        "regex": "Le champ :field est invalide",
        "required": "Le champ :field est obligatoire",
        "url": "Le champ :field doit être une url valide",
        "size": "Le champ :field doit avoir un poids inférieur à {size}KB",
        "creditCard": "Le champ :field doit être une carte de crédit valide",
        "date": "Le champ :field doit être une date valide",
        "minDate": "La date du champ :field doit être postérieur à :value",
        "maxDate": "La date du champ :field doit être antérieur à :value",
        "equal": "La valeur du champ :field doit être egale à :value",
        "lt": "La valeur du champ :field doit être inférieur à :value",
        "lte": "La valeur du champ :field doit être inférieur ou egale à :value",
        "gt": "La valeur du champ :field doit être supérieur à :value",
        "gte": "La valeur du champ :field doit être supérieur ou egale à :value",
        "equalTo": "Le champ :field ne correspond pas au champs :value."
    },
    "en": {
        "alpha": "Le champ :field ne peut contenir que des lettres",
        "alpha_num": "Le champ :field ne peut contenir que des caractères alpha-numériques",
        "alpha_dash": "Le champ :field ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
        "alpha_spaces": "Le champ :field ne peut contenir que des lettres ou des espaces",
        "range": "Le champ :field doit être compris entre :value et :value1",
        "confirmed": "Le champ :field ne correspond pas",
        "digits": "Le champ :field doit être un nombre entier de :value chiffres",
        "dimensions": "Le champ :field doit avoir une taille de {width} pixels par {height} pixels",
        "email": "Le champ :field doit être une adresse e-mail valide",
        "excluded": "Le champ :field doit être une valeur valide",
        "ext": "Le champ :field doit être un fichier valide",
        "image": "Le champ :field doit être une image",
        "integer": "Le champ :field doit être un entier",
        "length": "Le champ :field doit contenir :value caractères",
        "max": "Le champ :field doit avoir une valeur de {max} ou moins",
        "maxLength": "Le champ :field ne peut pas contenir plus de :value caractères",
        "mimes": "Le champ :field doit avoir un type MIME valide",
        "min": "Le champ :field doit avoir une valeur de {min} ou plus",
        "minLength": "Le champ :field doit contenir au minimum :value caractères",
        "number": "Le champ :field ne peut contenir que des chiffres",
        "oneOf": "Le champ :field doit être une valeur valide",
        "regex": "Le champ :field est invalide",
        "required": "Le champ :field est obligatoire",
        "url": "Le champ :field doit être une url valide",
        "size": "Le champ :field doit avoir un poids inférieur à {size}KB",
        "creditCard": "Le champ :field doit être une carte de crédit valide",
        "date": "Le champ :field doit être une date valide",
        "minDate": "La date du champ :field doit être postérieur à :value",
        "maxDate": "La date du champ :field doit être antérieur à :value",
        "equal": "La valeur du champ :field doit être egale à :value",
        "lt": "La valeur du champ :field doit être inférieur à :value",
        "lte": "La valeur du champ :field doit être inférieur ou egale à :value",
        "gt": "La valeur du champ :field doit être supérieur à :value",
        "gte": "La valeur du champ :field doit être supérieur ou egale à :value",
        "equalTo": "Le champ :field ne correspond pas au champs :value."
    }
}
export default messages;
