import React from "react";
import {createPopper} from "@popperjs/core";
import {logOut} from "../../../hooks/auth";

const UserDropdown = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start",
        });
        setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };
    return (
        <span onMouseLeave={() => closeDropdownPopover()}>
            <a
                className="text-white block"
                href="javascript:void()"
                ref={btnDropdownRef}
                onClick={(e) => {
                    e.preventDefault();
                    dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                }}
            >
                <div className="items-center flex">
          <span
              className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
                <i className="fa fa-user"> </i>
          </span>
                </div>
            </a>
            <div
                ref={popoverDropdownRef}
                className={
                    (dropdownPopoverShow ? "block " : "hidden ") +
                    "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                }
            >
                <a
                    href="javascript:void()"
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                    onClick={(e) => e.preventDefault()}
                >
                    {user.email}
                </a>
                <div className="h-0 my-2 border border-solid border-blueGray-100"/>
                <a
                    href="javascript:void()"
                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                    onClick={(e) => {
                        logOut();
                    }}
                >
                    Deconnexion
                </a>
            </div>
        </span>
    );
};

export default UserDropdown;
