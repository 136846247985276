import React from 'react'
import logo from 'assets/images/logo-wh.png'
import cover from 'assets/images/book-cover.png'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer class="w-full bg-gray-90">
            <div class="container xl:max-w-7xl mx-auto lg:px-16 px-4 pt-[53px] pb-16">
                <div class="flex flex-wrap lg:flex-nowrap">
                    <div class="w-full md:w-[290px] lg:mr-12 xl:mr-20 mb-8 lg:mb-0 text-center md:text-left">
                        <Link to="/" class="text-2xl font-black text-white uppercase inline-block mb-[30px]"> <img
                            src={logo} alt="" class="h-8" /> </Link>
                        <ul class="flex items-center justify-center md:justify-start space-x-4">
                            <li><Link to="#" class=""><svg width="22" height="18" viewBox="0 0 22 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M22 2.192c-.786.346-1.684.577-2.582.693.898-.577 1.684-1.5 2.02-2.539-.897.577-1.795.923-2.918 1.154A4.365 4.365 0 0 0 15.265 0c-2.47 0-4.49 2.077-4.49 4.615 0 .347 0 .693.113 1.039C7.184 5.424 3.816 3.577 1.57.808c-.336.692-.56 1.5-.56 2.307 0 1.616.785 3 2.02 3.808-.786 0-1.46-.23-2.02-.577 0 2.192 1.57 4.154 3.591 4.5-.337.115-.786.115-1.235.115-.336 0-.56 0-.898-.115.562 1.846 2.245 3.23 4.266 3.23-1.572 1.27-3.48 1.962-5.613 1.962-.336 0-.673 0-1.122-.115C2.02 17.193 4.378 18 6.96 18c8.305 0 12.795-7.039 12.795-13.154V4.27c.898-.346 1.684-1.154 2.245-2.077Z"
                                    fill="#fff" />
                            </svg></Link></li>

                            <li><Link to="#" class=""><svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19 3.15C19 1.477 17.523 0 15.85 0H3.15C1.477 0 0 1.477 0 3.15v12.7C0 17.523 1.477 19 3.15 19h6.4v-7.187H7.186v-3.15h2.362v-1.28c0-2.165 1.575-4.036 3.544-4.036h2.56v3.15h-2.56c-.295 0-.59.296-.59.886v1.28h3.15v3.15h-3.15V19h3.347c1.673 0 3.15-1.477 3.15-3.15V3.15Z"
                                    fill="#fff" />
                            </svg></Link></li>
                            <li><Link to="#" class=""><svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.125 11.038V18h-4.117v-6.538c0-1.613-.6-2.717-2.058-2.717-1.115 0-1.8.764-2.058 1.444a3.14 3.14 0 0 0-.172 1.018V18H6.604s.085-11.038 0-12.142h4.116v1.699c.515-.85 1.544-2.038 3.688-2.038 2.659 0 4.717 1.783 4.717 5.519ZM2.315 0C.944 0 0 .934 0 2.123 0 3.31.858 4.245 2.23 4.245c1.458 0 2.315-.934 2.315-2.122C4.631.849 3.774 0 2.315 0ZM.258 18h4.117V5.858H.257V18Z"
                                    fill="#fff" />
                            </svg></Link></li>
                        </ul>
                    </div>
                    <div class="w-full md:w-[220px] lg:mr-12 xl:mr-20 mb-8 lg:mb-0 flex justify-center">
                        <div>
                            <img src={cover} alt="book" class="w-[120px] mx-auto mb-[18px]" />
                                <Link to="#"
                                    class="flex items-center justify-center px-7 py-4 bg-black rounded-full text-sm text-white font-black space-x-3">
                                    <span>JE VEUX CE LIVRE</span> <svg width="8" height="13" viewBox="0 0 8 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </Link>
                        </div>
                    </div>
                    <div class="w-full lg:w-auto mb-8 lg:mb-0">
                        <div class="flex space-x-12 xl:space-x-20">
                            <ul class="">
                                <li><Link to="#" class="text-sm font-semibold text-white mb-5 inline-block">Coaching</Link></li>
                                <li><Link to="#" class="text-sm font-semibold text-white mb-5 inline-block">Mon histoire</Link>
                                </li>
                                <li><Link to="#" class="text-sm font-semibold text-white mb-5 inline-block">Mes livres</Link>
                                </li>
                            </ul>

                            <ul class="">
                                <li><a to="#" class="text-sm font-semibold text-white mb-5 inline-block">Privacy
                                    Policy</a></li>
                                <li><a to="#" class="text-sm font-semibold text-white mb-5 inline-block">Termes du
                                    service</a> </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <p class="text-sm sm:text-base text-white lg:-mt-8 text-center md:text-left">© 2021 Komi Afetse. All rights
                    reserved.</p>
            </div>
        </footer>
    )
}

export default Footer
