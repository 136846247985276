import React from 'react';
import Mainrouter from "Mainrouter";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
    return (
        <>
            <ReactNotification/>
            <Mainrouter/>
        </>

    );
}

export default App;
